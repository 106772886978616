import CurrentUserStore from './CurrentUser'
import CurrentChannelStore from './CurrentChannel'
import CommandsStore from './Commands'
import KeywordsStore from './Keywords'
import TimersStore from './Timers'
import AntispamStore from './Antispam'

class RootStore {
  constructor() {
    this.currentUser = new CurrentUserStore(this);
    this.currentChannel = new CurrentChannelStore(this);
    this.commands = new CommandsStore(this);
    this.keywords = new KeywordsStore(this);
    this.timers = new TimersStore(this);
    this.antispam = new AntispamStore(this);
  }
}

const rootStore = new RootStore();

export default rootStore;
