import React from 'react';
import PropTypes from 'prop-types';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      dark: '#1E262B',
      main: '#3f51b5',
    },
    background: {
      default: '#2D2E40',
      paper: '#2b343a',
      lighter: '#2b343a',
    },
  },
});

const PageWrapper = ({ children }) => (
  <div className="flex flex-col">
    <MuiThemeProvider theme={theme}>
      {children}
    </MuiThemeProvider>
  </div>
);

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

export default PageWrapper