import ApiRequestService from './ApiRequest';

const ApiService = {};

ApiService.getCurrentUser = () => ApiRequestService.get('auth/check');
ApiService.fetchChannelLogs = channel => ApiRequestService.get(`logs/${channel}`);
ApiService.fetchCommands = channel => ApiRequestService.get(`commands/${channel}`);
ApiService.fetchKeywords = channel => ApiRequestService.get(`keywords/${channel}`);
ApiService.fetchTimers = channel => ApiRequestService.get(`timers/${channel}`);
ApiService.fetchSpamWords = channel => ApiRequestService.get(`blacklisted_words/${channel}`);

ApiService.changeUser = channel => ApiRequestService.post(`auth/change_user`, channel)
ApiService.revertUser = () => ApiRequestService.delete(`auth/change_user`)

ApiService.editCommand = (channel, command) => ApiRequestService.put(`commands/${channel}`, command);
ApiService.createCommand = (channel, command) => ApiRequestService.post(`commands/${channel}`, command);
ApiService.deleteCommand = (channel, command) => ApiRequestService.delete(`delete/command/${channel}/${command}`);

ApiService.editKeyword = (channel, keyword) => ApiRequestService.put(`keywords/${channel}`, keyword);
ApiService.createKeyword = (channel, keyword) => ApiRequestService.post(`keywords/${channel}`, keyword);
ApiService.deleteKeyword = (channel, keyword) => ApiRequestService.delete(`delete/keyword/${channel}/${keyword}`);

export default ApiService;