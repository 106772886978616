import 'whatwg-fetch';
import keys from 'lodash/keys';
import ApiError from '../util/ApiError';
import config from '../../config';

const UNKNOWN_ERR = 'An unknown error occurred.';
const UNKNOWN_ERR_STATE = 500;

const cookieRx = /token=([^;]+)/i;

class ApiRequestService {
  constructor() {
    this.token = this.findCookie()
    this.headers = this.token ? { Authorization: `Bearer ${this.token}` } : {};
  }

  get(url, query = {}) {
    const queryString = keys(query).map(q => `${encodeURIComponent(q)}=${encodeURIComponent(query[q])}`).join('&');
    return new Promise((resolve, reject) => {
      fetch(config.api + url + (queryString.length > 0 ? `?${queryString}` : ''), { headers: this.headers })
        .then((response) => {
          response.json().then((json) => {
            if (json.error) {
              return reject(new ApiError(json.error || UNKNOWN_ERR, response.status));
            }
            return resolve(json);
          });
        })
        .catch(e => reject(new ApiError(e.message ? e.message.toString() : UNKNOWN_ERR, e.code || UNKNOWN_ERR_STATE)));
    });
  }

  // delete(url, body = {}) {
  //   return new Promise((resolve, reject) => {
  //     fetch(config.api + url, {
  //       headers: {
  //         ...this.headers,
  //         'Content-Type': 'application/json',
  //       },
  //       method: 'DELETE',
  //       body: JSON.stringify(body),
  //     }).then(response => response.json().then((json) => {
  //       if (json.error) {
  //         return reject(new ApiError(json.error || UNKNOWN_ERR, response.status));
  //       }
  //       return resolve(json);
  //     })).catch(e => reject(new ApiError(e.message ? e.message.toString() : UNKNOWN_ERR, e.code || UNKNOWN_ERR_STATE)));
  //   });
  // }

  delete(url, query = {}) {
    const queryString = keys(query).map(q => `${encodeURIComponent(q)}=${encodeURIComponent(query[q])}`).join('&');
    return new Promise((resolve, reject) => {
      fetch(config.api + url + (queryString.length > 0 ? `?${queryString}` : ''), {
        headers: this.headers,
        method: 'DELETE',
      }).then(response => response.json().then((json) => {
        if (json.error) {
          return reject(new ApiError(json.error || UNKNOWN_ERR, response.status));
        }
        return resolve(json);
      })).catch(e => reject(new ApiError(e.message ? e.message.toString() : UNKNOWN_ERR, e.code || UNKNOWN_ERR_STATE)));
    });
  }

  post(url, body = {}) {
    return new Promise((resolve, reject) => {
      fetch(config.api + url, {
        headers: {
          ...this.headers,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(body),
      }).then(response => response.json().then((json) => {
        if (json.error) {
          return reject(new ApiError(json.error || UNKNOWN_ERR, response.status));
        }
        return resolve(json);
      })).catch(e => reject(new ApiError(e.message ? e.message.toString() : UNKNOWN_ERR, e.code || UNKNOWN_ERR_STATE)));
    });
  }

  put(url, body = {}) {
    return new Promise((resolve, reject) => {
      fetch(config.api + url, {
        headers: {
          ...this.headers,
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify(body),
      }).then(response => response.json().then((json) => {
        if (json.error) {
          return reject(new ApiError(json.error || UNKNOWN_ERR, response.status));
        }
        return resolve(json);
      })).catch(e => reject(new ApiError(e.message ? e.message.toString() : UNKNOWN_ERR, e.code || UNKNOWN_ERR_STATE)));
    });
  }

  hasToken() {
    return !!this.token;
  }

  removeApiToken() {
    this.token = null;
    this.headers = {};
    document.cookie = `token=; Domain=${config.cookieHost}; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
    this.delete('auth/check');
  }

  findCookie() {
    if (this.token) {
      return this.token
    }
    const search = cookieRx.exec(document.cookie)
    if (search && search[1]) {
      return search[1]
    }else{
      return null
    }
  }
}

export default new ApiRequestService()