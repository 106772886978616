import React from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react';

const AuthLayout = ({ children, store }) => {

  if (store.currentUser.isAuthenticated) {
    return <Redirect to={`/dashboard`} />;
  }

  return(
    <div className="h-screen bg-gray-slate-darker">
      {children}
    </div>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default inject('store')(observer(AuthLayout));