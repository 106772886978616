import React from 'react'
import { NavLink } from 'react-router-dom'

const SidebarItem = ({ url, text, icon }) => {
  return (
    <NavLink to={url} activeClassName="bg-gray-slate-dark" className="block px-8 py-3 pr-20 text-gray-light hover:bg-gray-slate-dark">
      {icon}
      <span>{text}</span>
    </NavLink>
  )
}

export default SidebarItem
