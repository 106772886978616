const config = {
  development: {
    api: 'https://api.smokey.gg/',
    cookieHost: 'localhost'
  },
  production: {
    api: 'https://api.smokey.gg/',
    cookieHost: 'dev.smokey.gg'
  },
};

if (!config[process.env.NODE_ENV]) {
  throw new Error('No config found for environment.');
}

export default config[process.env.NODE_ENV];

