import { decorate, observable, action } from 'mobx';
import isEmpty from 'lodash/isEmpty';
import ApiService from '../services/Api'
import ApiRequest from '../services/ApiRequest'

class CurrentUserStore {

  constructor(){
    this.isAuthenticated = false
    this.user = {}
    this.mod_list = []
    this.isModding = false
    this.loading = false

    this.attemptLogin()
  }

  async attemptLogin() {
    const hasToken = ApiRequest.hasToken()
    if(!hasToken){ return }
    this.loading = true;
    await ApiService.getCurrentUser()
    .then((user) => {
      this.updateUserObj(user)
    }).catch((e) => {
      console.log(e)
      this.loading = false;
      this.error = e.message.toString();
      this.logout()
    });
  }

  async getUserMods() {
    const hasToken = ApiRequest.hasToken()
    if(!hasToken){ return }
    this.loading = true;
    await ApiService.getCurrentUser()
    .then(action((user) => {
      this.mod_list = user.mod_list
      this.loading = false;
    })).catch(action((e) => {
      this.loading = false;
      this.error = e.message.toString();
    }));
  }

  updateUserObj(user){
    if (!user.modding_for){
      this.user = user.user
      this.isAuthenticated = !isEmpty(user.user)
      this.loading = false;
    } else {
      this.isModding = true
      this.user = user.modding_for.user;
      this.isAuthenticated = !isEmpty(user.modding_for.user)
      this.loading = false;
    }
  }

  logout() {
    this.user = {};
    this.displayName = ''
    this.isAuthenticated = false;
    this.loading = false;
    ApiRequest.removeApiToken()
  }

}

export default decorate(CurrentUserStore, {
  attemptLogin: action,
  updateUserObj: action,
  logout: action,
  isAuthenticated: observable,
  user: observable,
  isModding: observable,
  loading: observable
})