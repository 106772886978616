import { action, decorate, observable } from 'mobx'
import ApiService from '../services/Api'

class CommandsStore {
  constructor(rootStore) {
    this.loading = false;
    this.error = null;
    this.commands = [];
    this.root = rootStore;
  }

  fetchCommands() {
    this.loading = true;
    this.commands = [];
    this.error = null;
    ApiService.fetchCommands(this.root.currentUser.user.twitch_id)
    .then(action("fetchSuccess", (commands) => {
      this.loading = false;
      this.commands = commands;
    }))
    .catch(action("fetchFail", (e) => {
      this.loading = false;
      this.error = e.message.toString();
      console.log(e)
    }));
  }

  toggleCommand(command) {
    const newCommand = { ...command, active: !command.active };
    ApiService.editCommand(this.root.currentUser.user.twitch_id, newCommand);
    this.commands = this.commands.map(c => (c.id === newCommand.id ? newCommand : c));
  }

}

export default decorate(CommandsStore, {
  fetchCommands: action,
  toggleCommand: action,
  root: observable,
  commands: observable,
  error: observable,
  loading: observable
})