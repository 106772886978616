import { action, decorate, observable } from 'mobx'
import ApiService from '../services/Api'

class TimersStore {
  constructor(rootStore) {
    this.loading = false;
    this.error = null;
    this.timers = [];
    this.root = rootStore;
  }

  async fetchTimers() {
    this.loading = true;
    await ApiService.fetchTimers(this.root.currentUser.user.twitch_id)
    .then((timers) => {
      this.updateTimers(timers)
    })
    .catch((e) => {
      this.loading = false;
      this.error = e.message.toString();
    });
  }

  updateTimers(timers){
    this.loading = false;
    this.timers = timers;
  }

}



decorate(TimersStore, {
  updateTimers: action,
  fetchTimers: action,
  timers: observable
})

export default TimersStore