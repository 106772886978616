import React, { Suspense, lazy} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import AsyncLoading from './pages/AsyncLoading';
import AdminLayout from './layouts/AdminLayout'
import AuthLayout from './layouts/AuthLayout'

const LoginPage = lazy(() => import('./pages/Login'));
const Loading = lazy(() => import('./pages/AsyncLoading'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Dashboard = lazy(() => import('./pages/Admin/Dashboard/Index'));
const Commands = lazy(() => import('./pages/Admin/Commands/Index'));
const Keywords = lazy(() => import('./pages/Admin/Keywords/Index'));
const Timers = lazy(() => import('./pages/Admin/Timers/Index'));
const Antispam = lazy(() => import('./pages/Admin/Antispam/Index'));
const ModLogs = lazy(() => import('./pages/Admin/Logs/ModLogs'));

const Routes = () => {
  return (
    <Suspense fallback={<AsyncLoading />}>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
        <Route path="/auth">
          <AuthLayout>
            <Route path="/auth/login" component={LoginPage} />
          </AuthLayout>
        </Route>
        <Route path="/login-redirect">
          <AuthLayout>
            <Route path="/login-redirect" component={Loading} />
          </AuthLayout>
        </Route>
        <AdminLayout>
          <Switch>
            <Route exact path="/" render={() => <Redirect to={`/dashboard`} />} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/commands" component={Commands} />
            <Route path="/keywords" component={Keywords} />
            <Route path="/timers" component={Timers} />
            <Route path="/antispam" component={Antispam} />
            <Route path="/logs">
              <Route path="/logs/mod" component={ModLogs} />
            </Route>
            <Route component={NotFound} />
          </Switch>
        </AdminLayout>
      </Switch>
    </Suspense>
  );
}

export default Routes;
