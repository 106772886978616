import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom'
import PageWrapper from '../../components/PageWrapper'
import Navbar from './components/Navbar'
import Sidebar from './components/Sidebar'
import Footer from '../../components/Footer'

class AdminLayout extends Component {
  state = { showSidebar: false }

  toggleSidebar() {
    document.body.classList.toggle("overflow-hidden");
    this.setState(state => ({ showSidebar: !state.showSidebar }));
  }

  closeSidebar() {
    document.body.classList.remove("overflow-hidden");
    this.setState({ showSidebar: false });
  }
  
  render(){
    const { children, store } = this.props;

    if (!store.currentUser.isAuthenticated && !store.currentUser.loading) {
      return <Redirect to="/auth/login" />;
    }

    return(
      <PageWrapper>
        <Navbar
          user={this.props.store.currentUser.user}
          toggleSidebar={() => this.toggleSidebar()}
        />
        <div className="flex flex-1 flex-row -mb-4">
          <div onClick={() => { this.closeSidebar() }} className={`overlay fixed ${this.state.showSidebar ? 'block' : 'hidden'} w-full h-full top-0 left-0 right-0 bottom-0 bg-black opacity-50 z-20 mt-16`}></div>
          <Sidebar
            channel={store.currentUser.user}
            showSidebar={this.state.showSidebar}
            closeSidebar={() => this.closeSidebar()}
          />
          <main id="content" className={`py-8 px-8 flex-auto overscroll-y-auto pt-24`}>
            {!!store.currentUser.user.twitch_id && children}
            <Footer />
          </main>
        </div>
      </PageWrapper>
    )
  }
}

export default inject('store')(withRouter(observer(AdminLayout)));