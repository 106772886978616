import React from 'react'
import { FaSpinner } from 'react-icons/fa'

export default function SpinnerLoad() {
  return (
    <div className="flex justify-center">
      <FaSpinner className="fill-current text-green-500 animate-spin w-16 h-16 opacity-50" />
    </div>
  )
}
