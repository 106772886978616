import React, { useState } from 'react'
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
import ModModal from './Modal'
import navLogo from '../../../../images/smokeybot_logo.svg'
import { FaSignOutAlt, FaBars } from 'react-icons/fa'

const Navbar = (props) => {
  const [modalOpen, setModalOpen] = useState(false)

  function logout(){
    props.store.currentUser.logout()
  }

  function openModal() {
    setModalOpen(true)
  }

  function closeModal() {
    setModalOpen(false)
  }

  return (
    <header className={`flex fixed h-16 items-center justify-between bg-green-800 py-4 px-8 w-full border-b border-green-600 shadow-lg z-30`}>
      <div className="hidden xl:flex items-center flex-shrink-0 text-white mr-10">
        <img className="h-10" src={navLogo} alt="SmokeyBot" />
      </div>
      <button onClick={() => props.toggleSidebar()} className="xl:hidden flex items-center flex-shrink-0 text-gray-light hover:bg-green-900 focus:outline-none py-2 px-3 rounded mr-2 transition duration-100 ease-in-out">
        <FaBars />
      </button>
      <div className="inline-flex">
        <div>
          <button onClick={() => { openModal() }} className="text-gray-light hover:bg-green-900 py-2 px-3 rounded mr-2 transition duration-100 ease-in-out font-normal focus:outline-none">
            {props.store.currentUser.user.twitch_display}
          </button>
        </div>
        <div>
          <button onClick={() => { logout() }} className="block lg:inline-block font-medium text-gray-light hover:bg-green-900 py-2 px-3 rounded transition duration-100 ease-in-out">
            <FaSignOutAlt className="fill-current w-5 h-5 inline-flex pb-1" />
          </button>
        </div>
      </div>
      {modalOpen && <ModModal user={props.store.currentUser} closeModal={() => closeModal()} />}
    </header>
  )
}

export default inject('store')(withRouter(observer(Navbar)));