import React from 'react';
import Card from '../components/Card';
import { FaSpinner } from 'react-icons/fa'

function LoadingPage(props){
  if (props.error) {
    return(
      <div className="flex justify-center">
      <div className="my-20">
        <Card className="px-10">
          <p className="text-white text-xl mb-4">Something went wrong.</p>
          <button className="text-white bg-green-800 rounded-sm py-2 px-6" onClick={ props.retry }>Click to retry</button>
        </Card>
      </div>
    </div>
    )
  } else {
    return(
      <div className="flex justify-center">
        <div className="my-20">
          <Card className="px-10">
            <FaSpinner className="fill-current text-green-500 animate-spin w-16 h-16 opacity-50" />
          </Card>
        </div>
      </div>
    )
  }
}

export default LoadingPage;