import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { inject, observer } from 'mobx-react'
import { GoVerified } from 'react-icons/go'
import SpinnerLoad from '../../../components/SpinnerLoad'
import ApiService from '../../../services/Api'

function Modal(props) {
  const [submitting, isSubmitting] = useState(false)

  useEffect(() => {
    props.store.currentUser.getUserMods()
  }, [props.store.currentUser])

  async function handleChange(e, uid){
    e.preventDefault()
    isSubmitting(true)
    await ApiService.changeUser({ mod_for: uid })
    .then(() => {
      window.location.reload(false);
    }).catch((err) => {
      console.log(err)
      isSubmitting(false)
    });
  }

  async function handleUnChange(e){
    e.preventDefault()
    isSubmitting(true)
    await ApiService.revertUser()
    .then(() => {
      window.location.reload(false);
    }).catch((err) => {
      console.log(err)
      isSubmitting(false)
    });
  }

  const { loading, mod_list } = props.user;

  let modalHTML =
  <form>
    <div id="modal-wrapper" className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center">
      <div onClick={props.closeModal} id="modal-backdrop" className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-50"></div>
      <div id="modal-content" className="w-3/12 relative rounded-lg shadow bg-gray-slate-dark py-6 px-6 text-gray-200 border-4 border-green-800">
        <p className="text-2xl">Manage channels</p>
        <span className="text-gray-400 text-sm">Channels you currently have access to manage.</span>
        <div className="mt-6 bg-gray-slate p-3 rounded text-left">
        {loading ? <SpinnerLoad /> :
          mod_list.map((mod) => {
            return(
              <div key={mod.uid}>
                <button disabled={submitting} onClick={(e) => handleChange(e, mod.uid)} className={`hover:bg-gray-slate-darker px-4 py-3 w-full font-semibold uppercase ${submitting ? 'cursor-not-allowed' : ''}`}>
                  {mod.display_name} {mod.type === 'partner' && <GoVerified className="fill-current w-5 h-5 ml-1 inline-flex pb-1" />}
                </button>
              </div>
            )
          })
        }
        </div>
        {props.store.currentUser.isModding && 
        <button disabled={submitting} onClick={(e) => handleUnChange(e)} className={`bg-gray-slate-darker px-4 py-3 w-full hover:underline font-semibold uppercase mt-4 ${submitting ? 'cursor-not-allowed' : ''}`}>
          Manage your own channel
        </button>}
      </div>
    </div>
  </form>
  return ReactDOM.createPortal(modalHTML, document.getElementById('modal-root'))

}

export default inject('store')(observer(Modal));
