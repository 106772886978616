import { action, decorate, observable } from 'mobx';
import isEmpty from 'lodash/isEmpty';
import ApiService from '../services/Api';

class CurrentChannelStore {
  constructor(rootStore) {
    this.channel = {};
    this.channelIde = null;
    this.logs = [];
    this.editor = {};
    this.loading = false
    this.error = null;
    this.sendingJoin = false;
    this.sendingMute = false;
    this.root = rootStore
  }

  refreshChannel() {
    this.loading = true;
    this.error = null;
    ApiService.getCurrentUser()
    .then((res) => {
      // this.editor = res.editor;
      // this.updateChannelStore(res._data.user);
      this.channel = res._data.user
      this.isAuthenticated = !isEmpty(res._data.user)
      this.loading = false;
    }).catch((e) => {
      // this.error = e.message.toString()
      console.log(e)
      this.loading = false;
    })
  }

  updateChannelStore(channel){
    console.log(channel)
    this.channel = channel
    this.isAuthenticated = !isEmpty(channel)
    this.loading = false;
  }

  async fetchLogs() {
    this.loading = true
    await ApiService.fetchChannelLogs(this.root.currentChannel.channel.twitch_id)
    .then((logs) => {
      this.loading = false;
      this.logs = logs[0];
    }).catch((e) => {
      this.loading = false;
      this.error = e.message.toString()
    })
  }

}

decorate(CurrentChannelStore, {
  updateChannelStore: action,
  refreshChannel: action,
  fetchLogs: action,
  logs: observable
})

export default CurrentChannelStore;