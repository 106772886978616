import React, { useState } from 'react'
import { FaCaretUp, FaCaretDown } from 'react-icons/fa'

export default function Card(props) {
  const [collapse, setCollapse] = useState(false)

  function contentCollapse(){
    setCollapse(!collapse)
  }

  return (
    <div className={` bg-gray-slate text-gray-200 rounded shadow mb-5 w-full`}>
      {props.title ?
      <div className="bg-green-800 py-2 px-6 rounded-t font-semibold">
        <div className="flex justify-between">
          {props.title}
          {props.collapse ?
          <button onClick={() => { contentCollapse() }} className="focus:outline-none">
            {collapse ? 
            <FaCaretDown className="fill-current relative w-6 h-6" /> 
            : 
            <FaCaretUp className="fill-current relative w-6 h-6" />
            }
          </button>
          : ''}
        </div>
      </div>
      :
      ''}
      <div className={`${ collapse ? 'hidden' : 'block' } ${props.className ? props.className : ''} p-${props.padding ? props.padding : '6'}`}>
        {props.children}
      </div>
    </div>
  )
}
