import React, { useState } from 'react'
import SidebarItem from './SidebarItem'
import { 
  FaTachometerAlt, 
  FaCommentAlt, 
  FaListAlt,
  FaClock,
  FaBars,
  FaUserTie,
  FaBullhorn,
  FaCoins,
  FaUserCircle,
  FaSpotify,
  FaMedal,
  FaCaretDown,
  FaCaretUp,
  FaCaretRight
  } from 'react-icons/fa'

export default function Sidebar({ channel, showSidebar, currentChannel }) {
  const [dropdown, setDropdown] = useState(false)

  function dropDown(){
    setDropdown(!dropdown)
  }

  const NavLinks = () => {
    return(
      <ul className="mb-10 w-full">
        <li className="pb-2 pt-4 text-gray-600 text-sm px-8">MENU</li>
        <SidebarItem url={`/dashboard`} text="Dashboard" icon={<FaTachometerAlt className="fill-current w-5 h-5 mr-6 inline-flex pb-1" />} />
        <li className="pb-2 px-8 pt-4 text-gray-600 text-sm">COMMANDS</li>
        <SidebarItem url={`/commands`} text="Custom" icon={<FaCommentAlt className="fill-current w-5 h-5 mr-6 inline-flex pb-1" />} />
        <SidebarItem url={`/keywords`} text="Keywords" icon={<FaBars className="fill-current w-5 h-5 mr-6 inline-flex pb-1" />} />
        <SidebarItem url={`/timers`} text="Timers" icon={<FaClock className="fill-current w-5 h-5 mr-6 inline-flex pb-1" />} />
        <li className="pb-2 px-8 pt-4 text-gray-600 text-sm">MANAGEMENT</li>
        <SidebarItem url={`/antispam`} text="Anti-spam" icon={<FaBars className="fill-current w-5 h-5 mr-6 inline-flex pb-1" />} />

        <button onClick={() => { dropDown() }} className={`flex justify-between px-8 w-full py-3 text-left text-gray-light hover:bg-gray-slate-dark focus:outline-none ${dropdown ? 'bg-gray-slate-dark' : ''}`}>
          <FaListAlt className="fill-current w-5 h-5 mr-6 pt-1 inline-flex relative" />
          Logs
          {dropdown ? <FaCaretUp className="fill-current w-5 h-5 ml-auto inline-flex pt-1 text-right" /> : <FaCaretDown className="fill-current w-5 h-5 ml-auto inline-flex pt-1 text-right" />}
        </button>

        <nav className={`bg-gray-slate-darker ${dropdown ? 'block' : 'hidden'}`}>
          <SidebarItem url={`/logs/mod`} text="Moderator" icon={<FaCaretRight className="fill-current w-5 h-5 mr-6 inline-flex pb-1" />} />
          <SidebarItem url={`/logs/bot`} text="SmokeyBot" icon={<FaCaretRight className="fill-current w-5 h-5 mr-6 inline-flex pb-1" />} />
          <SidebarItem url={`/logs/web`} text="Web" icon={<FaCaretRight className="fill-current w-5 h-5 mr-6 inline-flex pb-1" />} />
        </nav>

        <SidebarItem url={`/editors`} text="Editors" icon={<FaUserTie className="fill-current w-5 h-5 mr-6 inline-flex pb-1" />} />
        <SidebarItem url={`/adverts`} text="Adverts" icon={<FaBullhorn className="fill-current w-5 h-5 mr-6 inline-flex pb-1" />} />
        <SidebarItem url={`/loyalty`} text="Loyalty" icon={<FaCoins className="fill-current w-5 h-5 mr-6 inline-flex pb-1" />} />
        <SidebarItem url={`/channel`} text="Channel" icon={<FaUserCircle className="fill-current w-5 h-5 mr-6 inline-flex pb-1" />} />
        <li className="px-8 pb-2 pt-4 text-gray-600 text-sm">CONNECTIONS</li>
        <SidebarItem url={`/spotify`} text="Spotify" icon={<FaSpotify className="fill-current w-5 h-5 mr-6 inline-flex pb-1" />} />
        <li className="px-8 pb-2 pt-4 text-gray-600 text-sm">PUBLIC LINKS</li>
        <SidebarItem url={`/commands`} text="Commands" icon={<FaCommentAlt className="fill-current w-5 h-5 mr-6 inline-flex pb-1" />} />
        <SidebarItem url={`/leaderboards`} text="Leaderboards" icon={<FaMedal className="fill-current w-5 h-5 mr-6 inline-flex pb-1" />} />
      </ul>
    )
  }

  return (
    <div className={`sidebar fixed ${showSidebar ? 'block z-20' : 'hidden'} xl:block overflow-auto bg-gray-slate border-r-2 border-green-700 z-0 transition duration-200 ease-in-out`}>
      <div className="pt-4">
        <NavLinks />
      </div>
    </div>
  )
}
