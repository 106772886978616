import React from 'react'

export default function Footer() {
  return (
    <footer className="text-center mt-8 text-gray-light text-sm">
      <p className="opacity-50">© 2020, SmokeyBot Ltd. All rights reserved.</p>
      <div className="flex justify-center mt-2">
        <a href="https://google.com" className="text-gray-light opacity-50 hover:opacity-100 transition duration-200 ease-in-out mr-4">Documentation</a>
        <a href="https://google.com" className="text-gray-light opacity-50 hover:opacity-100 transition duration-200 ease-in-out mr-4">Support on Discord</a>
        <a href="https://google.com" className="text-gray-light opacity-50 hover:opacity-100 transition duration-200 ease-in-out mr-4">Terms of Service</a>
        <a href="https://google.com" className="text-gray-light opacity-50 hover:opacity-100 transition duration-200 ease-in-out">Privacy Policy</a>
      </div>
    </footer>
  )
}
