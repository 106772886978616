import { action, decorate, observable } from 'mobx'
import ApiService from '../services/Api'

class AntispamStore {
  constructor(rootStore) {
    this.loading = false;
    this.error = null;
    this.words = [];
    this.root = rootStore;
  }

  async fetchSpamWords() {
    this.loading = true;
    this.words = [];
    this.error = null;
    await ApiService.fetchSpamWords(this.root.currentUser.user.twitch_id)
    .then((words) => {
      this.loading = false;
      this.words = words;
    })
    .catch((e) => {
      this.loading = false;
      this.error = e.message.toString();
      console.log(e)
    });
  }
}

decorate(AntispamStore, {
  fetchSpamWords: action,
  words: observable
})

export default AntispamStore