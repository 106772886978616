import React from 'react'
import ReactDOM from 'react-dom'
import Routes from './app/routes'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'mobx-react';
import 'mobx-react-lite/batchingForReactDom'
import RootStore from './app/stores/Root'
import './app/css/tailwind.css'
import './app/css/custom.css'

ReactDOM.render(
  <Provider store={RootStore}>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();