import { action, decorate, observable } from 'mobx'
import ApiService from '../services/Api'

class KeywordsStore {
  constructor(rootStore) {
    this.loading = false
    this.error = null
    this.keywords = []
    this.root = rootStore
  }

  async fetchKeywords() {
    this.loading = true;
    await ApiService.fetchKeywords(this.root.currentUser.user.twitch_id)
    .then((keywords) => {
      this.updateKeywords(keywords)
    })
    .catch((e) => {
      this.loading = false;
      this.error = e.message.toString();
    });
  }

  updateKeywords(keywords){
    this.loading = false;
    this.keywords = keywords;
  }

}

decorate(KeywordsStore, {
  fetchKeywords: action,
  updateKeywords: action,
  keywords: observable,
  loading: observable
})

export default KeywordsStore